@import '../../../../vars.scss';

.AdditionalAssortment-addAdditionalAssortmentHeader {
	display: flex;
	align-items: center;
	background-color: $brand;
	color: var(--table-font-color);
	padding-left: 15px;
	justify-content: space-between;
	border-radius: 4px;
	height: 40px;
	width: auto;
	cursor: pointer;
	grid-column: 1;

	#AdditionalAssortment-spinner {
		font-size: 25px;
		width: 25px;
		height: 25px;
		margin-right: 8px;
	}
}

.AdditionalAssortment-addAdditionalAssortmentHeader-locked {
	background-color: rgba(var($brand), 0.3);
	color: var(--table-font-color);
	opacity: 0.7;
	cursor: default;
}

.AdditionalAssortment-additionalAssortmentContainer {
	grid-column: span 2;
}

.AdditionalAssortment-additionalAssortmentGrid {
	display: grid;
	margin-top: 5px;
	grid-template-columns: repeat(7, 1fr);
	gap: 10px;
}

.AdditionalAssortment-additionalGroupCard {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;

	.group-header {
		background-color: rgba(0, 0, 0, 0.03);

		p {
			font-weight: bold;
			margin-bottom: 10px;
		}

		padding: 5px 0;
	}

	.prd-line {
		padding: 10px 0 0 0;
	}
}

.AdditionalAssortment-additionalAssortmentGrid .number-input {
	height: 40px;
	margin-top: 5px;
	justify-content: center;

	.btn {
		width: 40px;
	}

	input {
		width: 40px;
	}
}

.AdditionalAssortment-buttonsContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.Popover-additionalAssortment .popover-body {
	display: inline-grid;
	grid-template-columns: 250px;
}

@media (max-width: 991.5px) {
	.AdditionalAssortment-additionalAssortmentContainer {
		margin-top: 15px;
	}

	.AdditionalAssortment-additionalAssortmentGrid {
		grid-template-columns: repeat(3, 1fr);
	}

	.AdditionalAssortment-addAdditionalAssortmentHeader {
		display: flex;
		align-items: center;
		background-color: $brand;
		padding: 10px;
		margin: 10px 0 0 0;
		justify-content: space-between;
		text-align: left;
	}

	.AdditionalAssortment-buttonsContainer {
		justify-content: center;
	}
}

@media (max-width: 551px) {
	.AdditionalAssortment-additionalAssortmentGrid {
		grid-template-columns: repeat(2, 1fr);
	}
}