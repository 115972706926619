// VARIABLES

$brand: var(--brand);
$brand-light: var(--brand-light);
$brand-bright: var(--brand-bright);
$table-font-color: var(--table-font-color);

$max-app-width: 1440px;

// FONTS

$font-stack: 'din-2014', Roboto Condensed, sans-serif;
$heading-font: 24px;
$primary-font: 18px;
$secondary-font: 14px;

// HEADINGS

$headings-margin-bottom: 1rem;

// NAVBAR, NAV

$nav-pills-border-radius: 0;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1rem;

// dark variant specific

$nav-pills-link-active-bg: $brand-light; // overriden in light variant

$navbar-dark-color: white;
$navbar-dark-hover-color: black;
$navbar-dark-active-color: black;

// light variant specific

$navbar-light-color: black;
$navbar-light-hover-color: white;
$navbar-light-active-color: white;

// TABLE

$table-striped-order: even;

// PAGINATION

$pagination-border-width: 0;
$pagination-color: black;
$pagination-active-bg: black;
$pagination-hover-color: white;
$pagination-hover-bg: black;

// OVERRIDE PRIMARY BUTTON

$primary: black;
