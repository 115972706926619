@import '../../../vars.scss';

/* <- Cards-category -> */
.Cards-category:not(:has(.edit-button:hover)):hover {
	box-shadow: 0px 0px 15px 2px $brand;
	cursor: pointer;
}

.Cards-category {
	width: calc(25% - 10px);
	margin: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background-color: rgb(248, 249, 250);
	border: 1px solid #CACACA;
	border-radius: 4px;
	box-sizing: border-box;
}

.Cards-category > * {
	margin: 0px;
}

.Cards-category > h3,
.Cards-category > p {
	margin: 10px 0;
}

.Cards-category > img {
	max-width: 100%;
	max-height: 100%;
	max-width: 150px;
	padding-bottom: 10px;
}

.custom-row {
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	position: relative;
}

.file-name {
	min-height: 38px;
	max-width: 14vw;
}

.edit-button {
	margin-left: 5px;
	margin-top: -18px;
}

.edit-button:hover {
	box-shadow: 0px 0px 10px 2px $brand;
	cursor: pointer;
	border-radius: 10px;
}

.edit-image-modal-header {
	height: 50px;
}

.edit-image-modal-body {
	height: 160px;
	background-color: #f5f5f5;
}

.edit-image-modal-footer {
	background-color: #e9e9e9;
}

.edit-image.fade {
	transition: none;
}

/* <- Cards-ProductDescriptionDetails -> */

.Cards-ProductDescriptionDetails {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	.card-title {
		font-weight: bold;
	}
}

.Cards-ProductDescriptionDetails-data-row {
	white-space: nowrap;
}

.Cards-ProductDescriptionDetails-data {
	text-align: left;
}

.Cards-ProductDescriptionDetails-photo {
	display: flex;
	flex-direction: column;
}

.Cards-ProductDescriptionDetails-photo-admin {
	display: flex;
	flex-direction: column;
}

/* MEDIA */
@media (min-width: 992px) {
	/* <- Cards-ProductDescriptionDetails -> */
	.Cards-ProductDescriptionDetails-photo {
		margin-top: auto;
		margin-bottom: auto;
		img {
			max-width: 440px;
			max-height: 206px;
		}
	}

	.Cards-ProductDescriptionDetails-photo-admin {
		margin-top: auto;
		margin-bottom: auto;
		img {
			max-width: 440px;
			max-height: 180px;
			margin-left: auto;
		}
	}

	/* <- Cards-category -> */
	.Cards-category > img {
		max-height: 300px;
		margin-top: auto;
		margin-bottom: auto;
	}
}

@media(max-width: 991.5px) {
	/* <- Cards-category -> */
	.Cards-category {
		margin: 5px auto;
		width: 100%;
		min-height: 90px;
		flex-direction: row-reverse;
		justify-content: center;
		display: grid;
		grid-template-columns: 70px calc(100% - 70px);
		grid-template-rows: 1fr;
		column-gap: 5px;
	}

	.Cards-category > h3.NewOrder-heading-with-subs {
		grid-row: 1 / span 1;
	}

	.Cards-category > h3 {
		width: 100%;
		grid-column: 2;
		grid-row: 1;
		padding-left: 5px;
	}

	.Cards-category > img {
		max-width: 70px;
		max-height: 70px;
		margin-right: 5px;
		margin-left: 15px;
		padding-bottom: 0px;
		grid-column: 1;
		grid-row: 1 / span 2;
		place-self: center;
	}

	.Cards-category > p {
		grid-column: 2;
		grid-row: 2;
		margin: 0;
	}
	
	/* <- Cards-ProductDescriptionDetails -> */
	.Cards-ProductDescriptionDetails {
		align-items: start;

		.list-group {
			margin-top: auto;
			margin-bottom: auto;
		}
	}


	.Cards-ProductDescriptionDetails-photo {
		align-items: flex-end;
		justify-content: center;
		margin-top: auto;
		margin-bottom: auto;

		img {
			max-width: 200px;
			max-height: 200px;
		}
	}

	.Cards-ProductDescriptionDetails-photo-admin {
		align-items: flex-end;
		justify-content: center;
		margin-top: auto;
		margin-bottom: auto;

		img {
			max-width: 200px;
			max-height: 200px;
		}
	}

	.file-name {
		max-width: 100%;
	}
}

@media (max-width: 551px) {
	/* <- Cards-ProductDescriptionDetails -> */
	.Cards-ProductDescriptionDetails {
		flex-direction: column;
		align-items: center;
	}

	.Cards-ProductDescriptionDetails-data{
		margin-left: -5rem;
	}

	.Cards-ProductDescriptionDetails-photo {
		padding: 20px 0 0 0;

		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			max-height: 300px;
			max-width: 300px;
		}
	}

	.Cards-ProductDescriptionDetails-photo-admin {
		padding: 20px 0 0 0;

		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			max-height: 300px;
			max-width: 300px;
		}
	}
}

@media (max-width: 400px) {
	.Cards-ProductDescriptionDetails-data{
		margin-left: 0;
	}

	.Cards-ProductDescriptionDetails-photo {
		img {
			max-height: 300px;
			max-width: 200px;
		}
	}

	.Cards-ProductDescriptionDetails-photo-admin {
		img {
			max-height: 300px;
			max-width: 200px;
		}
	}
}