@import '../../vars';

.ButtonComponent-buttonDesktop,
.ButtonComponent-buttonMobile {
	border: none;
	height: 42px;
}

.ButtonComponent-buttonDesktop:focus,
.ButtonComponent-buttonMobile:focus {
	box-shadow: none;
}

.ButtonComponent-buttonDesktop:hover, 
.ButtonComponent-buttonDesktop:active,
.ButtonComponent-buttonMobile:hover,
.ButtonComponent-buttonMobile:active {
	box-shadow: none;
}

.ButtonComponent-buttonMobile {
	font-size: 14px;
}
