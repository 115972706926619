#spin {
	margin: auto auto;
}

#spinner,
#spinner-centered {
	display: flex;
	flex-direction: column;
	width: 200px;
	height: 100px;
	background-color: #f5f5f5;
	border-radius: 10px;
	margin: 20px auto;
}

#spinner:has(button),
#spinner-centered:has(button) {
	width: 240px;
	height: 120px;
}

#spinner-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#error {
	width: 150px;
	margin: auto auto;
	text-align: center;
}

@media (max-width: 768px) {

	#spinner {
		width: 150px;
	}
}
