.AdditionalTab-header-container {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
}

.AdditionalTab-container-element {
    align-items: baseline;
}

.AdditionalTab-container {
    max-width: 1100px;
    margin: auto;
}

.AdditionalTab-Switch {
    margin-top: 10px;
}