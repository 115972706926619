.search-form-mobile {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 5px;
	margin-bottom: 0.5rem;
}

.search-button {
	display: flex;
	align-items: flex-end;
}

.adv-search-buttons-row {
	display: grid;
	grid-template-columns: 1fr 4fr;
}