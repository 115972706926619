@import 'vars';

// NAVBAR, NAV

.navbar {
	text-transform: uppercase;
}

.nav-link {
	height: 100%;
}

// dark variant specific

.navbar-dark {
	background-color: $brand;
	.nav-item:hover {
		background-color: $brand-light;
	}
}

// light variant specific

.navbar-light {
	background-color: #eaeaea;
	.nav-pills {
		.nav-link.active,
		.show > .nav-link {
			background-color: $brand; // override $nav-pills-link-active-bg for light variant
		}
	}
	.nav-item:hover {
		background-color: $brand;
	}
}

// TABLE

.table {
	thead,
	tfoot {
		background-color: $brand;
	}

	thead {
		text-transform: uppercase;
	}
}


.row,
.form-row {
	margin: 0 !important;
}

// OVERRIDE PRIMARY BUTTON

$theme-colors: (
	'primary': $primary,
);

// OVERRIDE ALERT SUCCESS

.alert-success {
	border-color: rgb(0, 200, 0) !important;
}

//OVERRIDE MODAL

.modal-header {
	background-color: $brand;
	color: #fff;
}

.modal-header .close {
	color: var(--table-font-color);
	&:hover {
		color: var(--table-font-color);
	}
}

.modal-body {
	background-color: #fff;
}
