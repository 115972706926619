.news-container {
	max-width: 1100px;
	margin: auto;

	img {
		max-width: 100% !important;
	}
}

.list-group-item {
	p{
		overflow-wrap: break-word;
	}
}

.archived-news {
	background-color: #EEE;
}

.expired-news {
	background-color: #EEE;
}

@media(max-width: 992px) {
	.news-container img {
		height: auto !important;
	}
}
