@import '../../vars.scss';

a.LinkItem-anchor, a.LinkItem-anchor:hover {
	text-decoration: none;
}

.LinktItem {
	transition: 0.3s;
	:hover::after {
		transition-timing-function: ease;
	}
}