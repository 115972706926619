@import '../../../vars.scss';

.TreeRowElement-Edit-Button {
    margin-left: 5px;
    margin-bottom: 2px;
    margin-top: -3px;
}

.TreeRowElement-Edit-Button:hover {
    box-shadow: 0px 0px 5px 2px $brand;
	cursor: pointer;
	border-radius: 10px;
}