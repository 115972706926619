.mobile-user-block {
	display: flex;
	padding: 1em;
	justify-content: center;
	align-items: center;

	&:nth-child(even) {
		background-color: #eee;
	}

	.mobile-user-block-login {
		flex: 2;
		word-break: break-all;
	}

	.mobile-user-block-role {
		flex: 3;
	}

	.mobile-user-block-state {
		flex: 1;
	}
}

.AdminNotificationComponent {
	position: absolute !important;
	width: auto !important;
	text-align: right !important;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.Admin-roleSelect {
	width: 40%;
	margin: 0 auto;
}

@media (max-width: 992px) {

	.Admin-datetime-input {
		display: inline-block;
		width: calc(100% - 30px);
		max-width: 200px;
	}
	
}

.vertical-align-center {
	td {
		vertical-align: middle;
	}
}