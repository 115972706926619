.desktop-font-size {
    font-size: 20px;
    line-height: 1;
}

.desktop-hr {
    width: 700px;
}

.Table-cell {
    vertical-align: middle !important;
}

.TestsResults-container {
    display: flex;
    justify-content: center;
}

.TestsResults-body {
    width: 100%;
}

.error-icon {
    color: red;
    transition: 0.3s;
}

.error-icon:hover {
    cursor: pointer;
    background-color: lightgrey;
    border-radius: 5px;
}

.mobile-font-size {
    font-size: 12px;
}

.Table-test-class-name {
    background-color: white;
}

.Table-test-iteration {
    padding: 8px !important;
}