.search-column {
	margin: 10px 0;
}
.search-button-mobile-adv {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
}

.dialog-settings {
	max-width: 592px;
	margin: auto;
}

.OrderStatusPopup {
	border: 1px solid #000;
	background-color: #fff;
	width: 600px;
	height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	left: 10px;
	bottom: 10px;
	z-index: 1001;
	cursor: move;
	font-size: 0.9em;
}

.OrderStatusPopup-header {
	border-radius: 0;
	align-items: flex-start;
	font-size: 1.15rem;
	color: #000;

	.close {
		font-size: 1em;
		padding: 5px;
	}
}

.OrderStatusPopup-content {
	width: 100%;
	padding: 5px;
}

.OrderStatusPopup-content-orderNumber {
	text-align: left;
	font-weight: bold;
	padding: 5px 0 0 0;
}

.OrderStatus-table {
	table-layout: fixed;
	width: 100%;

	tbody {
		text-align: left;
	}

	td, th {
		text-wrap: wrap;
		vertical-align: middle;
		padding: 5px;
	}
}

.OrderStatus-body-mobile-part {
	padding: 1em;

	h6 {
		font-weight: bold;
	}

	&:nth-child(even) {
		background-color: #eee;
	}

	> div {
		display: flex;
		align-items: center;

		hr {
			flex-grow: 1;
			margin: 0.5rem;
			margin-right: 5px;
			margin-left: 5px;
			border: 1px dashed #ddd;
		}
	} 
}

.OrderTooltip {
	z-index: 999;
	width: 300px;
	padding: 6px;
	border: 1px solid #e2e2e2;
	background-color: #fff;
	color: #000;

	&_message {
		width: 100%;
		height: min-content;
		font-size: 16px;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&_label {
			font-weight: bold;
		}
	}
}