@import 'vars';

.page-item.active .page-link {
    background-color: $brand;
}

.page-item .page-link {
    font-size: 1rem;
}

.pagination {
    border: 1px solid grey;
    padding: 1px;
}